import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const IS_BOY_FIRST = true; //change to true for switch broadcast text

export const GIRL_NAME = 'Briptu Ardianti Zalukhu, S.H.';
export const GIRL_NAME_SHORT = 'Anti';
export const GIRL_FATHER_NAME = `Kompol (Purn) Sabar Kasih Zalukhu`;
export const GIRL_MOTHER_NAME = `Hatiani Baeha`;
export const GIRL_PARENT_NAME = 
  LANG === 'en'
    ? `<br />The Daughter of:  <br />Mr. ${GIRL_FATHER_NAME}<br />and Mrs. ${GIRL_MOTHER_NAME}`
    : `<br />Putri dari:  <br />Bapak ${GIRL_FATHER_NAME}<br />dan Ibu ${GIRL_MOTHER_NAME}`
;

export const BOY_NAME = 'Ajun Jaksa Madya Fraditio Perwira Pranantama, S.H., M.H.';
export const BOY_NAME_SHORT = 'Dio';
export const BOY_FATHER_NAME = `Irjen Pol (Purn) Drs. Rudi Pranoto`;
export const BOY_MOTHER_NAME = `Fransisca Retno Hardiyati, S.H.`;
export const BOY_PARENT_NAME = 
  LANG === 'en'
    ? `<br />The Son of: <br />Mr. ${BOY_FATHER_NAME}<br />and Mrs. ${BOY_MOTHER_NAME}`
    : `<br />Putra dari: <br />Bapak ${BOY_FATHER_NAME}<br />dan Ibu ${BOY_MOTHER_NAME}`
;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}` 
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`
;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'fraditio';
export const IG_GIRL = '46anty';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/josantoso/dio-anti/seo.jpg?updatedAt=1701849906038`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`
;

/**
 * @constants - epoch time
 * this configuration for wedding time
 * use this website for convert local time to epoch time
 * https://www.epochconverter.com/
 */
export const EPOCH_START_EVENT = 1705719600;
export const EPOCH_RESEPSI_START = 1705719600;
export const EPOCH_END_EVENT = 1705762800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-20');
export const DATE_RESEPSI = new Date('2024-01-20');

export const WEDDING_DATE_DAY = dayjs(DATE_AKAD).locale(LANG).format('dddd');
export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_AKAD = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '10.00 WIB - selesai';
export const WEDDING_LOCATION_AKAD = 'Gereja BNKP Batam Center';

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).locale(LANG).format('dddd');
export const WEDDING_RESEPSI = WEDDING_DATE_DATE;
export const WEDDING_RESEPSI_TIME = '18.00 - 22.00 WIB';
export const WEDDING_LOCATION = 'Olympus Ballroom Lantai 9 <br />Pasific Palace Hotel';
export const WEDDING_RESEPSI_CITY = 'Batam';
export const WEDDING_LOCATION_ROAD = `Jl. Duyung Sungai Jodoh, Kec. Batu Ampar, Kota Batam`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).locale(LANG).format('DD.MM.YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://DioAnti.com/';
export const BROADCAST_WEDDING_LOCATION = `Olympus Ballroom Lantai 9 Pasific Palace Hotel, Kota Batam`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/7ujWDmwiLT8reTfo6`;
export const GOOGLE_MAPS_ADDRESS = `Alamat lengkap dapat dilihat di undangan website`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Dewa 19 - Aku Milikmu`;
export const SOUND_URL = 'https://youtu.be/8u8eqztIHyc?si=6ukh05a-qndv5MA0';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/RMEF_WtinuI?si=aOx7SCMMocOAby7P';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/RMEF_WtinuI?si=aOx7SCMMocOAby7P';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbx2K5auNPqBpIQ2B4eQzVtZFFh-Lg747sRYLJicfTTrYyk5Xslsrh1MQSLlE4kHv4NC/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';